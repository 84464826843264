import React, { useState } from 'react'
import Layout from '../components/layout'
import { Section, Wrapper } from '../components/baseStyles'
import Paragraph from '../components/paragraph'
import Title from '../components/title'
import SEO from '../components/seo'
import Button from '../components/button'
import styled from 'styled-components'
import TextInputGroup from '../components/textinputgroup'
import { white, extraLightGray, black, gray } from '../utlis/colors'
import bookLarge from '../images/ebook_desktop.jpg'
import bookSmall from '../images/ebook_mobile.jpg'

import { navigate } from 'gatsby'
import addToMailChimp from 'gatsby-plugin-mailchimp'

const FromWrap = styled.div`
  background: ${extraLightGray};
  padding: 70px calc((100% / 12) * 4) 60px calc((100% / 12) * 2);
  @media (max-width: 640px) {
    padding: 15px;
    margin: 0 -15px;

    button {
      margin-left: auto;
      margin-right: auto;
      display: table;
    }
  }
  input {
    background-color: ${white}!important;
    border: 1px solid ${gray};
    border-radius: 10px;
  }
`

const Picture = styled.picture`
  display: block;
  margin: 0 -100px;
  width: calc(100% + 200px);
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
  img {
    max-width: 100%;
  }
`

const Ul = styled.ul`
  color: #020512;
  font-size: 22px;
  line-height: 34px;
  padding-left: 40px;
  margin: 30px 0;
  li {
    margin-bottom: 5px;
  }
  @media (max-width: 640px) {
    font-size: 17px;
    line-height: 24px;
    padding-left: 25px;
    li {
      margin-bottom: 15px;
    }
  }
`
const ConsentBox = styled.div`
  font-size: 13px;
  color: ${black};
  line-height: 18px;
  padding-left: 40px;
  position: relative;
  margin-bottom: 20px;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 1;
  width: 1;
  color: red;
`

const Checkmark = styled.span`
  position: absolute;
  top: -2px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${props => (props.on === true ? 'gray' : 'white')};
  border-radius: 10px;
  border: 1px solid ${gray};
  :after {
    content: '';
    left: 8px;
    top: 4px;
    width: 5px;
    position: absolute;
    height: 10px;
    border: solid ${white};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const PreuzmiVodic = ({ location, pageContext: { locale } }) => {
  const [formState, setFormState] = useState({
    name: '',
    tel: '',
    email: '',
    newsletter: false,
    gdpr: false,
    error: {},
  })
  console.log('formState', formState);

  const formSubmit = async e => {
    console.log('clicked')
    e.preventDefault()
    try {
      const response = await fetch('.netlify/functions/sendPdf', {
        method: 'POST',
        body: JSON.stringify(formState),
      })
      localStorage.setItem(
        'eBook',
        JSON.stringify({
          downloaded: true,
          time: new Date(),
        })
      )
      if (formState.email && formState.newsletter) {
        await addToMailChimp(formState.email, {
          LANG: locale,
        })
      }
      const date = new Date()
      const datetime = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`

      fetch('.netlify/functions/saveCustomer',{
        method: 'POST',
        body: JSON.stringify({...formState,date:datetime })
      })
      if (response && response.ok) {
        navigate('/hvala-na-prijavi')
      }
      

    } catch (error) {
      console.error(error)
    }
  }

  const onChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }
  return (
    <Layout
      heroImage={null}
      isConditions={false}
      title="Besplatni vodič za uspješno iznajmljivanje smještaja"
      text="Imate vrhunski opremljen apartman koji zjapi prazan, ali ne znate kako početi sa iznajmljivanjem? Stigli ste na pravo mjesto!"
      locale={locale}
      hideMeeting={true}
      isEBookPage={true}
      cta={{
        content: 'Preuzmi eBook',
        onClick: () =>
          document.querySelector('[name="name"]').scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest',
          }),
      }}
    >
      <SEO
        title="Preuzmi besplatni vodič za uspješno iznajmljivanje smještaja"
        keywords={['Litto']}
        lang={locale}
        description="Imate vrhunski opremljen apartman koji zjapi prazan, ali ne znate kako početi sa iznajmljivanjem? Stigli ste na pravo mjesto!"
      />

      <Wrapper>
        <Section>
          <Paragraph offset="30px">
            Litto je splitska turistička agencija kojoj je primarni cilj
            podizanje kvalitete turističkih objekata i iskustva za goste
            implementiranjem novih tehnologija i praćenjem svjetskih trendova u
            turizmu.
          </Paragraph>
          <Picture>
            <source media="(min-width: 768px)" srcSet={bookLarge} />
            <img src={bookSmall} />
          </Picture>
          <Title offset="30px" medium>
            Litto eBook - korak po korak.
          </Title>
          <Paragraph>
            Procesi kategorizacije apartmana, online oglašavanja i
            iznajmljivanja smještajne jedinice mogu biti izrazito komplicirani
            ukoliko ne znate u kojem smjeru tražiti. Zato smo za vas osmislili
            ovu knjižicu s uputama kojom vas želimo savjetovati oko vođenja
            računa i rezervacija, oglašavanja, evidenciji prometa i ostalim
            bitnim stavkama kako bi od vas u konačnici napravili uspješne
            iznajmljivače u turizmu.
          </Paragraph>
          <Ul>
            <li>Kako početi s iznajmljivanjem?</li>
            <li>Priprema i uređenje objekta za iznajmljivanje</li>
            <li>Moderan izgled i uređenje objekta</li>
            <li>Čistoća i održavanje kao baza vašeg uspjeha</li>
            <li>Priprema dokumentacije i registracija objekta</li>
            <li>Kako definirati idealnu cijenu smještaja?</li>
            <li>Kako napisati najbolji opisni tekst za vaš objekt?</li>
            <li>Administrativne obveze na koje morate paziti</li>
            <li>Važni uvjeti za pravne osobe</li>
            <li>Sve što trebate znati o procesu prijave gostiju</li>
            <li>Izdavanje računa - knjige evidencije prometa</li>
            <li>Plaćanje boravišne pristojbe i paušala</li>
            <li>Recenzije i komunikacija s gostima</li>
          </Ul>
        </Section>
        <FromWrap>
          <form onSubmit={formSubmit} name="pdfForm" >
            <Title medium>
              Nemoj biti stranac!
              <br />
              Preuzmi besplatni Litto eBook
            </Title>
            <Paragraph offset="50px">
              Ostavi nam svoj kontakt mail kako bismo ti u budućnosti mogli
              slati još korisnih materijala koji će ti pomoći oko vođenja
              rezervacija, u komunikaciji s gostima, oglašavanju apartmana i
              praćenja najnovijih trendova iz svijeta turizma.
            </Paragraph>
            <TextInputGroup
              name="name"
              type="text"
              label="Ime"
              onChange={onChange}
            />
            <TextInputGroup
              name="tel"
              type="number"
              label="Phone"
              onChange={onChange}
            />
            <TextInputGroup
              name="email"
              type="email"
              label="Email"
              onChange={onChange}
            />
            <ConsentBox>
              <Input
                type="checkbox"
                id="newsletter"
                name="newsletter"
                onChange={e =>
                  setFormState({
                    ...formState,
                    newsletter: !formState.newsletter,
                  })
                }
                // required
                checked={formState.newsletter}
              />
              <Checkmark
                onClick={() =>
                  setFormState({
                    ...formState,
                    newsletter: !formState.newsletter,
                  })
                }
                on={formState.newsletter}
              />
              <label htmlFor="newsletter">
                Želim primati mjesečne informacije putem Litto Booking
                newsletter-a
              </label>
            </ConsentBox>
            <ConsentBox>
              <Input
                type="checkbox"
                id="gdpr"
                name="gdpr"
                required
                onChange={e =>
                  setFormState({ ...formState, gdpr: !formState.gdpr })
                }
                // required
                checked={formState.gdpr}
              />
              <Checkmark
                onClick={() =>
                  setFormState({ ...formState, gdpr: !formState.gdpr })
                }
                on={formState.gdpr}
              />
              <label htmlFor="gdpr">
                Ovim putem želim tvrtki Litto d.o.o. omogućiti tražene
                informacije u zamjenu za pristup informativnom vodiču, u skladu
                sa pravilima privatnosti, uvjetima i odredbama navedenim na web
                stranici tvrtke.
              </label>
            </ConsentBox>
            <Button bigger white submit>
              Preuzmi besplatno
            </Button>
          </form>
        </FromWrap>
      </Wrapper>
    </Layout>
  )
}

export default PreuzmiVodic
